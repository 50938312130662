// Import bootstrap variables to make them available to imports of this file
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@function kdgh-color($color-map, $shade: default, $opacity: 1) {
  @return opacify(map-get($color-map, $shade), $opacity);
}

// Colors
$kdgh-turquoise: (
  default: #00a388,
  lighter: #66c8b8,
  darker: #005244,
  contrast-default: white,
  contrast-lighter: #333333,
  contrast-darker: white,
);

$kdgh-magenta: (
  default: rgb(153, 0, 102),
  lighter: rgb(194, 102, 163),
  darker: rgb(77, 0, 51),
  contrast-default: white,
  contrast-lighter: white,
  contrast-darker: white,
);

$kdgh-blue: (
  default: rgb(96, 153, 165),
  lighter: rgb(160, 194, 201),
  darker: rgb(48, 77, 83),
  contrast-default: white,
  contrast-lighter: #333333,
  contrast-darker: white,
);

$kdgh-primary: $kdgh-turquoise;
$kdgh-accent: $kdgh-magenta;
$kdgh-info: $kdgh-blue;

// Background
$body-bg: #e6e6e6;
$nav-bar-bg: white;
$background-dark: #2d2d2d;

// Foreground
$text-info: rgba(0, 0, 0, 0.9);
$text-hint: rgba(0, 0, 0, 0.8);
$text-light: #bfbfbf;

// Bootstrap variables
$brand-primary: kdgh-color($kdgh-primary);
$brand-info: kdgh-color($kdgh-info);
$brand-accent: kdgh-color($kdgh-accent);

$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $brand-primary,
    'secondary': $brand-accent,
    'info': $brand-info,
  )
);

$link-color: kdgh-color($kdgh-turquoise);

$font-family-base: 'Fira Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif;
$headings-font-family: 'Roboto Slab';
