@mixin anchor {
  a.no-style {
    &,
    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
      color: inherit;
    }
  }
}
